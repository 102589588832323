import tw, { css, styled } from 'twin.macro';

export const HomePageSection = styled.div([
  css`
    display: grid;
    gap: 32px;
    margin-top: -16px;
    padding-top: 32px;
    padding-bottom: 32px;
    @media (min-width: 768px) {
      max-width: 560px;
    }

    @media (min-width: 1000px) {
      max-width: calc(280px * 3);
    }

    @media (min-width: 1200px) {
      gap: 56px;
      margin-top: -40px;
      padding-top: 0px;
      max-width: calc(280px * 4);
    }

    @media (min-width: 1500px) {
      max-width: calc(280px * 5);
    }
  `,
  tw`
    mx-auto 
    w-full 
    desktop:px-0 
    max-w-[1400px]
      `,
]);

export const ProductPageSection = styled.div([
  css`
    transition: all 0.8s;
    margin: auto;
    &.open {
      padding-left: 32.5rem;
      padding-right: 120px;
    }
    
      @media (min-width: 768px) {
      width: calc(280px * 2);
    }

    @media (min-width: 1000px) {
      width: calc(280px * 3);
    }

    @media (min-width: 1000px) {
      width: calc(280px * 4);
    }

    @media (min-width: 1500px) {
      width: calc(280px * 5);
    }

    @media (min-width: 1800px) {
      width: calc(280px * 6);
    }

    @media (min-width: 2100px) {
      width: calc(280px * 7);
    }
  `,
]);
